import React, { useEffect } from "react";
import "../index.css";

export default function Welcome() {
  useEffect(() => {
    document.title = 'Aarhus Symposium';
  }, []);
  return (
    <div>
      <p
        className="h3 text-center mb-4 white-text futuraFuture"
        style={{ paddingTop: "4rem" }}
      >
        Welcome to
      </p>
      <p
        className="h1 text-center mb-4 white-text futuraFuture"
        style={{ paddingBottom: "3rem" }}
      >
        Aarhus Symposium
      </p>
      <p className="h5 text-center mb-4 white-text futuraFuture">
        The page you were trying to access is no longer active.
      </p>
      <p className="h5 text-center mb-4 white-text futuraFuture">
        Please try again later!
      </p>
    </div>
  );
}
