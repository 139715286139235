import {
  MDBBox,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBIcon,
} from "mdbreact";
import "../index.css";
import { useLocation } from 'react-router-dom';
import React, { useEffect } from "react";
import axios from "axios";
import { SocketContext } from "../context/socket";
import { Socket } from "socket.io-client";

type WritingWorkshopEntry = {
  name: string;
  email: string;
};

export default function WritingWorkshop() {
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const codeParam = params.get('code');
    if (codeParam && code !== codeParam) { // Only update if it’s different
      setCode(codeParam);
      // Optionally validate the code if necessary here
      validateCode(codeParam);
      if(!validCode){
        setCode("");
      }
    }

    document.title = 'Writing Workshop';

  }, []);
  let submittedCode = "";
  const [code, setCode] = React.useState<string>("");
  const [name, setName] = React.useState<string>("");
  const [validCode, setValidCode] = React.useState<boolean>(false);
  const [email, setParticipantEmail] = React.useState<string>("");
  const [emailErrorMessage, setEmailErrorMessage] = React.useState<string>("");
  const [codeErrorMessage, setCodeErrorMessage] = React.useState<string>("");
  const [agree, setAgreement] = React.useState<boolean>(false);
  const [hasBeenSubmitted, setSubmit] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>("");

  const socket: Socket = React.useContext(SocketContext);

  const validateEmail = (email) => {
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    if (!emailPattern.test(email)) {
      setEmailErrorMessage("Invalid email format");
    } else {
      setEmailErrorMessage("");
    }
  };

  const validateCode = (value) => {
    const date = new Date();
    const year = date.getFullYear().toString();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const yearMonthDay = year + month + day;
  
    // Encode the yearMonthDay to Base64
    const base64EncodedDate = btoa(yearMonthDay).replace(/=+$/, '').toLowerCase();
  
    // Validate the input value
    if (value === base64EncodedDate) {
      submittedCode = value;
      setCodeErrorMessage("");
      setValidCode(true);
    } else {
      setCodeErrorMessage("Invalid code");
      setValidCode(false);
    }
    };
  
  const handleSubmit = async (e: any) => {
    e.preventDefault();
    validateEmail(email);
    validateCode(code);

    if (emailErrorMessage || codeErrorMessage) {
      return;
    }


    const data: WritingWorkshopEntry = {
      name: name,
      email: email,
    };

    axios
      .post(`https://${window.location.hostname}/strapi/writingworkshops`, data)
      .then((res) => {
        socket.emit("newEntry", "");
        setSubmit(true);
      })
      .catch((err) => {
        setErrorMessage("Sorry, something went wrong. Please try again later.");
      });
  };



  return (
    <div className="bg">
      <MDBContainer>
        <MDBBox>
          <br />
          <br />
          <br />
        </MDBBox>
        <MDBRow center>
          <MDBCol md="6">
            {!hasBeenSubmitted ? (
              <form onSubmit={handleSubmit}>
                <p
                  className="h1 text-center mb-4 white-text futuraFuture"
                  style={{ paddingBottom: "2rem" }}
                >
                  Aarhus Symposium
                </p>
                {!validCode && (
                  <>
                    <label
                      htmlFor="defaultFormContactEmailEx"
                      className="white-text"
                    >
                      Workshop Code:
                    </label>

                    <input
                      required={true}
                      type="text"
                      id="defaultFormContactCodeEx"
                      className={`form-control ${codeErrorMessage ? "is-invalid" : ""}`}
                      value={code}
                      onChange={(e) => setCode(e.target.value)}
                      onBlur={(e) => validateCode(e.target.value)}
                      style={{ marginBottom: "2em" }}
                    ></input>
                    {codeErrorMessage && (
                      <div className="invalid-feedback">{codeErrorMessage}</div>
                    )}
                  </>
                )}
                <br></br>
                <p
                  className="h4 text-center mb-4 white-text futuraFuture"
                  style={{ marginTop: "-1em", marginBottom: "-1em" }}
                >
                  Please fill in your personal information below to participate
                </p>
                <br></br>
                <label
                  htmlFor="defaultFormContactEmailEx"
                  className="white-text"
                >
                  Your name:
                </label>

                <input
                  required={true}
                  type="text"
                  id="defaultFormContactNameEx"
                  className="form-control"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  style={{ marginBottom: "2em" }}
                  autoComplete="name"
                ></input>

                <label
                  htmlFor="defaultFormContactEmailEx"
                  className="white-text"
                >
                  Your email:
                </label>

                <input
                  required={true}
                  type="text"
                  id="defaultFormContactNameEx"
                  className={`form-control ${
                    emailErrorMessage ? "is-invalid" : ""
                  }`}
                  value={email}
                  onChange={(e) => {
                    setParticipantEmail(e.target.value);
                  }}
                  onBlur={(e) => validateEmail(e.target.value)}
                  autoComplete="email"
                />
                {emailErrorMessage && (
                  <div className="invalid-feedback">{emailErrorMessage}</div>
                )}
                <br />

                <div className="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="defaultUnchecked"
                    value="accept"
                    onChange={(e) => {
                      setAgreement(agree ? false : true);
                    }}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="defaultUnchecked"
                    style={{ color: "white" }}
                  >
                    By registering for this workshop, I accept that Aarhus Symposium
                    may send information regarding events and important
                    deadlines, and I accept the{" "}
                    <a href="https://aarhus-symposium.org/terms">
                      Privacy Policy & Terms of Service
                    </a>
                  </label>
                </div>

                {errorMessage && (
                  <p
                    className="text-center mt-4 futuraFont"
                    style={{ color: "red" }}
                  >
                    {errorMessage}
                  </p>
                )}

                <div className="text-center mt-4 futuraFont">
                  <MDBBtn color="light" outline type="submit" disabled={!agree}>
                    Send
                    <MDBIcon far icon="paper-plane" className="ml-2" />
                  </MDBBtn>
                </div>
                <br />
              </form>
            ) : (
              <p className="h1 text-center mb-4 white-text futuraFont">
                SUCCESS
              </p>
            )}
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </div>
  );
}
