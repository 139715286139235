import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
  useLocation,
} from "react-router-dom";
import { SocketContext, socket } from "./context/socket";
import GuessWho from "./components/guesswho";
import Marathon2023 from "./components/marathon2023";
import ChallengeLaunch from "./components/challengelaunch";
import GuessTheTheme from "./components/guessthetheme";
import Welcome from "./components/welcome";
import Questions from "./components/questions";
import WritingWorkshop from "./components/writingworkshop";
import ClueCompetition from "./components/cluecompetition";

const CSSLoader = () => {
  const location = useLocation();

  useEffect(() => {
    // Extract the path from the current location
    const path = location.pathname.slice(1) || "index";
    // Construct the CSS URL
    const cssUrl = `https://files.asymp.org/css/${path}.css`;

    // Create a link element to load the CSS
    const link = document.createElement("link");
    link.rel = "stylesheet";
    link.href = cssUrl;

    // Append to head
    document.head.appendChild(link);

    // Cleanup on unmount
    return () => {
      document.head.removeChild(link);
    };
  }, [location.pathname]);

  return null;
};


const AppContent = () => {
  return (
    <>
      <CSSLoader /> {/* Dynamically load the CSS based on the path */}
      <div className="bg">
        <Switch>
          <Route path="/" exact component={Welcome} />
          <Route path="/guesswho" exact component={GuessWho} />
          <Route path="/marathon-2023" exact component={Marathon2023} />
          <Route path="/competition" exact component={ChallengeLaunch} />
          <Route path="/guessthetheme" exact component={GuessTheTheme} />
          <Route path="/questions" exact component={Questions} />
          <Route path="/writingworkshop" exact component={WritingWorkshop} />
          <Route
            path="/clue:clueId"
            exact
            component={({ match }) => <ClueCompetition clueId={match.params.clueId} />}
          />
          <Redirect to="/" />
        </Switch>
      </div>
    </>
  );
};

function App() {
  return (
    <SocketContext.Provider value={socket}>
      <div className="App">
        <Router>
          <AppContent />
        </Router>
      </div>
    </SocketContext.Provider>
  );
}

export default App;
